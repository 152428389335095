import React from 'react'
import cx from 'classnames';
import styles from './hero.module.scss';
import settings from '../../settings';

const Hero = ({ children, className, classNames = [] }) => (
  <div
    className={cx(styles.hero, className, classNames.map(cn => styles[cn]), { [styles.guideHero]: !!settings.IsInterviewGuide })}
  >
    <div className={cx('container')}>
      { children }
    </div>
  </div>
)

export default Hero;
