import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { lifecycle } from 'recompose';
import PropTypes from 'prop-types';
import cx from 'classnames';
import IntercomChat, { IntercomAPI } from 'react-intercom';
import Layout from '../../components/Layout';
import ArrowRight from '../../assets/arrow-right.svg';
import Hero from '../../components/Hero/Hero';
import InstallCEButton from '../../components/InstallCEButton/InstallCEButton';
import { newlines } from '../../utils';

import styles from './interview-guides.module.scss';

export const HomepageTemplate = ({
  title1,
  title2,
  subtitle,
  img,
  heroBlocks = [],
  featuresTitle,
  features,
  location,
}) => (
  <Layout location={location} id='interviewGuides'>
    <div className={cx('homepage')}>
      <Hero classNames={['mainHero']}>
        <div className={cx('text')} style={{ textAlign: 'center' }}>
          <div className={cx(styles.mainDisplay)}>
            <h1 className="display-3">{title1}&nbsp;</h1>
            {title2 && <h1 className="display-1">{newlines(title2)}</h1>}
          </div>
          { subtitle && <h4>{subtitle }</h4> }
          <InstallCEButton className='btn-lg' />
        </div>
      </Hero>
      { heroBlocks.map(hero => (
        <div
          key={hero.className}
          className={cx(styles.homeHero, styles[hero.className])}
        >
          <div className={cx(styles.relativeWrap, 'container1')}>
            <div className={cx(styles.heroContent)}>
              {hero.className === 'productRight' && (
                <div className={cx(styles.imageContainer)}>
                  <img
                    className={cx(styles.gif)}
                    alt="Hero Pic"
                    src={hero.img}
                  />
                </div>
              )}
              <div
                className={cx(styles.contentContainer)}
                style={{ backgroundImage: `url("/images/accents/${hero.accent}.svg")`}}
              >
                <div style={{ marginTop:'40px', marginBottom: '40px'}}>
                  <h2 className="display-2">{ newlines(hero.title) }</h2>
                  { hero.subtitle && <p className="big">{hero.subtitle }</p> }
                  <p>
                    <a
                      style={{
                        color:'#ff2e57',
                        textDecoration: 'none',
                        fontWeight: '700',
                      }}
                      href='/contact'
                    >{hero.cta} <ArrowRight className='svg-red' style={{ height: '12px'}} />
                    </a>
                  </p>
                </div>
              </div>
              {hero.className === 'productLeft' && (
                <div className={cx(styles.imageContainer)}>
                  <img
                    className={cx(styles.gif)}
                    alt="Hero Pic"
                    src={hero.img}
                  />
                </div>
              )}
            </div>
            <div
              className={cx(styles.heroFeature)}
              style={{ backgroundImage: `url("${hero.img}")`}}
            />
          </div>
        </div>
      ))}
      <section className={cx('features')}>
        <div className={cx('container')}>
          <h2 style={{ marginBottom: '2.4rem' }}><img className='mb-1 mr-2' src='/images/accents/small-wave.svg' alt="" /> {featuresTitle} </h2>
          <div className={cx('inline-container', 'ic-4', 'ai-top')}>
            {features.map(feature => (
              <div key={feature.title}>
                <img src={feature.img} alt={feature.title} className={cx('mb-2')} />
                <h4 className='mb-2'>{feature.title}</h4>
                <p className='mb-2'>{feature.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

HomepageTemplate.propTypes = {
  // title: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

HomepageTemplate.defaultProps = {
  subtitle: null,
};

const Homepage = lifecycle({
  componentDidMount() {
    IntercomAPI('boot');
  },
})(({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query InterviewGuidesQuery {
          markdownRemark(frontmatter: { name: { eq: "interviewGuides" } }) {
            frontmatter {
              title1
              title2
              subtitle
              logos {
                logo
              }
              img
              heroBlocks {
                title
                subtitle
                className
                accent
                img
                cta
              }
              quoteHero {
                logo
                quote
                quotedName
                quotedRole
                quotedImg
                img
                flip
              }
              featuresTitle
              features {
                title
                img
                text
              }
            }
          }
        }
      `}
      render={(data) => {
        const {frontmatter} = data.markdownRemark
        return (
          <span>
            <HomepageTemplate
              location={location}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...frontmatter}
            />
            <IntercomChat appID="rg0xc7fz" />
          </span>
        )
      }}
    />
  );
});

Homepage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default Homepage;

// export const InterviewGuidesQuery = graphql`
//   query InterviewGuidesQuery {
//     markdownRemark(frontmatter: { name: { eq: "interviewGuides" } }) {
//       frontmatter {
//         title1
//         title2
//         subtitle
//         logos {
//           logo
//         }
//         img
//         heroBlocks {
//           title
//           subtitle
//           className
//           accent
//           img
//           cta
//         }
//         quoteHero {
//           logo
//           quote
//           quotedName
//           quotedRole
//           quotedImg
//           img
//           flip
//         }
//         featuresTitle
//         features {
//           title
//           img
//           text
//         }
//       }
//     }
//   }
// `;
